<template>
  <div class="trainingWrap container">
    <header>
      <headerTop></headerTop>
      <navTab></navTab>
      <div class='mcd'>
        <img src="../../assets/mbc.png" alt="">
      </div>
    </header>
    <div class="main-content">
      <div class='topInfo'>
        <div class='photo'>
          <img :src="photoUrl" alt="">
        </div>
        <div class='name'>
          <div style='margin-bottom:15px'>Hi,</div>
          <div>学员 {{userName}} 同学</div>
        </div>
        <div class='statistics'>
          <div class='today_study'>
            <div>学习班次</div>
            <div><span>{{courseCount}}</span>次</div>
          </div>
          <div class='today_test'>
            <div>证书数量</div>
            <div><span>{{certCount}}</span>张</div>
          </div>
        </div>
      </div>
      <div class='contentBox'>
        <div class='conten-left'>
          <!-- <div class='nowStudyCourse' v-show='liveCourseList.length==0'>
            <div class='nowStudyCourseLeft'></div>
            <div class='nowStudyCourseRight' style='line-height: 149px'>暂无直播课</div>
          </div>
          <div class='nowStudyCourse' v-for="(item,index) in liveCourseList" :key="index">
            <div class='nowStudyCourseLeft'>
              <img src="../../assets/image/homeImg/jingpinkechengBg.png" alt="">
            </div>
            <div class='nowStudyCourseRight'>
              <div class='title1'>{{item.courseTitle}}</div>
              <div class='title1'>{{item.chapterTitle}}</div>
              <div class='time'>今天：{{item.time}}  &nbsp;&nbsp;&nbsp;讲课人：{{item.chapterTeacher}}</div>
            </div>
          </div> -->
          <div class='tabs'>
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane  :label="`我的课程(${recordsData.length})`" name="first">
                  <div class='couseList'>
                    <div class='courseCard' v-for="(item,index) in recordsData" :key='index'>
                      <div class='title'>{{item.courseTitle}}</div>
                      <div class='progress'>
                        <div class='processBox' v-if="item.processRate<100">
                          <!-- 已学：<el-progress :percentage="item.processRate" color='#FF6F00'></el-progress> -->
                        </div>
                        <div v-else>时间：{{item.createTime}}</div>
                        <div><el-button class='entryStudy' type="primary" @click='jumpCourseInfo(item)'>进入学习</el-button></div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane :label="`我的证书(${certificatesListData.length})`" name="second">
                  <div class='zsList'>
                    <div class='zsItem' v-for="(item,index) in certificatesListData" :key="index">
                      <div class='title'>
                        <div class='courseTitle'>课程：<span>{{item.courseTitle}}</span></div>
                        <div class='ststus ststus_2' v-show="item.status=='PASS'">状态：<span>已达标</span></div>
                        <div class='ststus ststus_3' v-show="item.status=='NOT_PASS'">状态：<span>未达标</span></div>
                      </div>
                      <div class='cover' v-show="item.status=='PASS'">
                        <img class='zsImg' :src="item.certImg_url" alt="">
                        <img class='downLoadImg' @click='downloadZs(item)' src="../../assets/downloadIcon.png" alt="" srcset="">
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
          </div>
        </div>
        <div class='conten-right'>
          <div class='title'>
            工具箱
          </div>
          <ul class='tool'>
            <li @click='jumpToolPage(1)'>
              <img src="../../assets/wdzy.png" alt="">
              <div>我的作业</div>
            </li>
            <li @click='jumpToolPage(2)'>
              <img src="../../assets/wdsj.png" alt="">
              <div>我的试卷</div>
            </li>
            <li @click='jumpToolPage(3)'>
              <img src="../../assets/wjpg.png" alt="">
              <div>问卷评估</div>
            </li>
            <li @click='jumpToolPage(4)'>
              <img src="../../assets/grzx.png" alt="">
              <div>个人中心</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
import headerTop from '@/components/header.vue'
import navTab from '@/components/navTab.vue'
export default {
  name: '',
  components: {
    footerCom,
    headerTop,
    navTab
  },
  props: {},
  watch: {},
  data () {
    return {
      userName: '',
      recordsData: [],
      activeIndex1: 0,
      activeIndex2: 0,
      activeName: 'first',
      liveCourseList: [],
      liveCount: 0,
      photoUrl: '',
      certificatesListData: [],
      certCount: 0,
      courseCount: 0,
    }
  },
  methods: {
    pageJumpLogin () {
      this.$router.push('/login')
    },
    jumpCourseInfo (item) {
      let courseId = item.courseId
      if (item.isMultiple == 'Y') {
        this.$router.push(`/childrenChaptersCourse/${courseId}`)
      } else {
        this.$router.push(`/overCourseInfo/${courseId}`)
      }
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    getRecordsData () {
      let reqOption = {
        pageNo: 1,
        pageSize: 999,
        params: {
          courseType: 'VOD',
        },
      }
      console.log(this.$apiUrl.queryStudentCoursePage)
      this.$axios
        .post(this.$apiUrl.queryStudentCoursePage, reqOption)
        .then((res) => {
          let { records } = res.data.data
          this.recordsData = JSON.parse(JSON.stringify(records))
          console.log(this.recordsData)
        })
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
    // 获取证书
    getQueryStudentCertificateList () {
      this.$axios.post(this.$apiUrl.queryStudentCertificateList).then((res) => {
        if (res.data.success) {
          this.certificatesListData = res.data.data
        }
      })
    },
    // 获取今天的直播课
    getloadTodayLiveCourse () {
      this.$axios
        .get(this.$apiUrl.loadTodayLiveCourse, { params: {} })
        .then((res) => {
          if (res.success) {
            let { liveCount, todayLiveChapterList } = res.data
            this.liveCount = liveCount
            this.liveCourseList = JSON.parse(
              JSON.stringify(todayLiveChapterList)
            )
          }
        })
    },
    jumpToolPage (type) {
      console.log(type)
      if (type == 1) {
        this.$router.push('/myHomeWork?courseId=0')
      } else if (type == 2) {
        // this.$router.push('/myExamList')
        this.$message({
          type: 'warning',
          message: '此功能暂未开放！'
        })
      } else if (type == 3) {
        this.$router.push('/diaochawenjuan')
      } else if (type == 4) {
        this.$router.push('/userCenterPage')
      }
    },
    getLoadStudentExtend () {
      this.$axios.post(this.$apiUrl.loadStudentExtend, {}).then((response) => {
        console.log(response)
        if (response.data.success) {
          let res = response.data
          console.log(res, '-----------------------------------')
          this.photoUrl = res.data.photoUrl
            ? res.data.photoUrl
            : require('../../assets/user_photo.png')
        }
      })
    },
    // loadStudentInfo
    getLoadStudentInfo () {
      this.$axios.post(this.$apiUrl.loadStudentInfo, {}).then((response) => {
        if (response.data.success) {
          let res = response.data.data
          this.courseCount = res.courseCount
          this.certCount = res.certCount
        }
      })
    },
    downloadZs (item) {
      console.log(item)
      window.open(item.certImg_url)
    }
  },
  mounted () {
    this.photoUrl = require('../../assets/user_photo.png')
    this.getQueryStudentCertificateList()
    this.getRecordsData()
    this.getUserInfo()
    this.getloadTodayLiveCourse()
    this.getLoadStudentExtend()
    this.getLoadStudentInfo()
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;

  header {
    background: url('../../assets/image/homeImg/headerBg.png');
    background-size: 100% 100%;
  }
  .mcd {
    height: 413px;
    img {
      width: 100%;
      height: 413px;
    }
  }
}
.main-content {
  width: 1240px;
  padding-bottom: 188px;
  margin: 0 auto 0;
  padding: 30px;
  padding-bottom: 188px;
  background-color: transparent;
  margin-top: -340px;
  .topInfo {
    display: flex;
    margin-bottom: 90px;
    /* background: url('../../assets/mbc.png') no-repeat; */
    background-size: 100% 100%;
    justify-content: center;
    .photo {
      width: 120px;
      height: 174px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      border: 1px solid #707070;
      img {
        width: 118px;
        height: 172px;
      }
    }
    .name {
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      font-size: 28px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      span {
        padding-right: 20px;
      }
    }
    .statistics {
      flex: 1;
      display: flex;
      justify-content: end;
      align-items: center;
      font-size: 22px;
      color: #ffffff;
      span {
        font-size: 30px;
      }
      .today_study {
        padding: 20px;
        border-right: 1px solid #cecece;
        margin-right: 20px;
      }
    }
  }
  .contentBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 266px;
    .conten-left {
      width: 790px;
      .nowStudyCourse {
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 6px 30px 1px rgba(0, 0, 0, 0.12);
        border-radius: 7px 7px 7px 7px;
        opacity: 1;
        margin-bottom: 53px;
        display: flex;
        padding: 20px;
        .nowStudyCourseLeft {
          width: 224px;
          height: 149px;
          margin-right: 30px;
          img {
            width: 224px;
            height: 149px;
          }
        }
        .nowStudyCourseRight {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          .title1 {
            margin-bottom: 15px;
          }
          .title2 {
            font-size: 20px;
            margin-bottom: 15px;
          }
          .time {
            font-size: 14px;
          }
        }
      }
      .tabs{
        /deep/ .el-tabs__item{
          font-size: 16px;
          color: rgba(255,255,255,0.6);
        }
         /deep/ .is-active{
            color: #fff
          }
          /deep/ .el-tabs__active-bar{
            background-color: #fff
          }
      }
      .couseList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px 0px;
        .courseCard {
          width: 48%;
          background: #ffffff;
          box-shadow: 0px 6px 30px 1px rgba(0, 0, 0, 0.12);
          border-radius: 7px 7px 7px 7px;
          opacity: 1;
          padding: 20px;
          margin-bottom: 20px;
          .title {
            font-size: 16px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            margin-bottom: 15px;
          }
          .progress {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            .processBox{
              display: flex;
              ::v-deep .el-progress{
                width: 106px;
              }
            }
            .entryStudy{
              width: 96px;
              background: #4774DF;
              border-radius: 20px 20px 20px 20px;
            }
          }
        }
      }
      .zsList {
        /* display: flex;
        flex-wrap: wrap;
        justify-content: space-between; */
        padding: 30px 0px;
        .zsItem {
          /* width: 48%; */
          display: flex;
          padding: 18px 35px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #525455;
          background: #ffffff;
          box-shadow: 0px 6px 30px 1px rgba(0, 0, 0, 0.12);
          border-radius: 10px 10px 10px 10px;
          opacity: 1;
          margin-bottom: 20px;
          .title {
            flex: 1;
            display: flex;
            flex-direction: column;
            .courseTitle {
              margin-bottom: 20px;
              span {
                font-size: 18px;
              }
            }
            .status {
              span {
                font-size: 16px;
              }
            }
            .ststus_2 {
              span {color: #0090FF;}
            }
            .ststus_3 {
              span {color: #cecece;}
            }
          }
          .cover {
            width: 152px;
            height: 86px;
            display: flex;
            justify-content: center;
            align-items: center;
            .zsImg {
              width: 122px;
              height: 86px;
            }
            .downLoadImg{
              width: 22px;
              height: 22px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .conten-right {
      width: 320px;
      height: 404px;
      background: #ffffff;
      box-shadow: 0px 6px 30px 1px rgba(0, 0, 0, 0.12);
      border-radius: 7px 7px 7px 7px;
      opacity: 1;
      padding: 0px 30px;
      .title {
        padding: 20px 0px 10px 0px;
        font-size: 20px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        border-bottom: 1px solid #707070;
      }
      .tool {
        display: flex;
        flex-wrap: wrap;
        > li {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 30px 0px;
          cursor: pointer;
          img {
            width: 60px;
            height: 60px;
            margin-bottom: 10px;
          }
          div {
            font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
        }
      }
    }
  }
}
</style>
